import * as React from 'react';
import { RFQ, RFQPostModel, RFQRecipient } from '../interfaces/interfaces'; // Keep this import
import { Modal2 } from '../components/Modal';
import { Loader } from '../components/Loader';
import { useState } from 'react';
import {
  FieldFilterableSelect,
  FieldInput,
  FieldTextArea,
} from "../components/FieldComponents/index";
import { vendorsAndSubsSelector } from '../store/vendors/vendors.selectors';
import { VendorsActions } from '../store/vendors/index';
import { RFQAddModal, RFQRecipientModal } from './RFQAddModal'; // Import the modal
import { Bool } from '../../node_modules/reselect/src/types';


interface Props {
  rfq: RFQ;
  updateRfq: (r: RFQ) => void;
  close: () => void;
  canEdit: boolean;
}


const formatDateInputFriendly = (date: string | undefined): string => {
  if (!date) return '';
  const d = new Date(date);
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

export const RFQDetailModal: React.FC<Props> = ({ rfq, updateRfq, close, canEdit }) => {
  const [localRfq, setLocalRfq] = useState({ ...rfq } as RFQ);
  const [editingRecipientIdx, setEditingRecipientIdx] = useState<number | null>(null);
  const [showRecipientModal, setShowRecipientModal] = useState<boolean>(false);
  const [saving, setSaving] = React.useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.type === 'checkbox' ? (e.currentTarget as HTMLInputElement).checked : e.currentTarget.value;
    setLocalRfq({ ...localRfq, [field]: value });
  };

  const _handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.currentTarget.name;
    // @ts-ignore
    const files = e.currentTarget.files || null;
    setLocalRfq({ ...localRfq, [field]: files });
  };

  const [hasNewRecipients, setHasNewRecipients] = useState<boolean>(false);


  const _handleAddRecipient = (recipient: RFQRecipient) => {
    const isDuplicate = localRfq.recipients.some((r) => r.vendorId === recipient.vendorId);
    if (!isDuplicate) {
      setLocalRfq({ ...localRfq, recipients: localRfq.recipients.concat([recipient]) });
      setHasNewRecipients(true);
    } else {
      alert("This recipient has already been added.");
    }
    setShowRecipientModal(false);
  };

  const _handleRecipientEdit = (recipient: RFQRecipient) => {
    if (editingRecipientIdx !== null) {
      // Update the recipients array with the updated recipient
      const updatedRecipients = [...rfq.recipients];
      updatedRecipients[editingRecipientIdx] = recipient;

      // Update the RFQ state with the updated recipients array
      setLocalRfq({ ...rfq, recipients: updatedRecipients });
      // Set the editing recipient index to null
      setEditingRecipientIdx(null)
    }
  };

  const _handleRemoveRecipient = (recipient: RFQRecipient) => {
    const updatedRecipients = localRfq.recipients.filter(r => r !== recipient);
    setLocalRfq({ ...localRfq, recipients: updatedRecipients });
  };

  React.useEffect(() => {
    console.log(localRfq)
  }, [localRfq])


  const handleSave = (send: boolean) => {
    if (!localRfq.dueDate) {
      alert('Due Date is required');
      return;
    }
    console.log('Saving RFQ:', localRfq);
    setSaving(true);

    const rfqPostModel: RFQPostModel = {
      recipients: localRfq.recipients,
      rfqDescription: localRfq.rfqDescription,
      dueDate: localRfq.dueDate,
      specAttached: localRfq.specAttached,
      deliveryRequiredBy: localRfq.deliveryRequiredBy,
      specialInstructions: localRfq.specialInstructions,
      itemDescription: localRfq.itemDescription,
      projectId: localRfq.projectId,
      send: send,
      itemId: localRfq.itemId,
      spec: null,
      bccProcurementManager: localRfq.bccProcurementManager
    };

    const form = new FormData();
    form.append('json', JSON.stringify(rfqPostModel));
    if (localRfq.spec) {
      for (let i = 0; i < localRfq.spec.length; ++i) {
        form.append('spec', localRfq.spec[i], localRfq.spec[i].name)
      }
    }

    fetch(`api/RFQ/updateRFQ?rfqId=${localRfq.id}`, { method: 'PUT', body: form })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          updateRfq(data);
        } else {
          alert(data.message);
        }
        setSaving(false)
      });
  };



  const toggleRecipientModal = () => {
    setShowRecipientModal(!showRecipientModal)
  };


  return (
    <Modal2>
      <Loader loading={saving} />
      <div className="modal-header">
        <h5>{canEdit ? 'Edit RFQ' : 'View RFQ'}</h5>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <label>Item Description</label>
          <input
            type="text"
            className="form-control"
            value={localRfq.itemDescription}
            onChange={(e) => setLocalRfq({ ...localRfq, itemDescription: e.target.value })}
            readOnly={!canEdit}
          />
        </div>
        <div className="form-group">
          <label>RFQ Description</label>
          <textarea
            className="form-control"
            value={localRfq.rfqDescription}
            onChange={(e) => setLocalRfq({ ...localRfq, rfqDescription: e.target.value })}
            readOnly={!canEdit}
          />
        </div>
        <div className="form-group">
          <label>Due Date</label>
          <input
            type="date"
            className="form-control"
            value={formatDateInputFriendly(localRfq.dueDate)}
            onChange={(e) => setLocalRfq({ ...localRfq, dueDate: e.target.value })}
            readOnly={!canEdit}
            required
          />
        </div>
        <div className="form-group">
          <label>Spec Attached</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="checkbox"
            field="specAttached"
            value={localRfq.specAttached}
            update={handleInputChange}
          />
        </div>
        {localRfq.specAttached ?
          <div className="form-group">
            <label>Spec</label>
            <input
              type="file"
              name='spec'
              onChange={_handleFileInputChange}
            />
          </div>
        : []}
        {(localRfq.attachments && localRfq.attachments.length) ?
          <div className='form-group'>
            <label>Existing Attachments</label>
            {localRfq.attachments.map(x => <div key={x.fileName}>{x.fileName}</div>)}
          </div>
        : []}
        <div className="form-group">
          <label>Delivery Required By</label>
          <input
            type="date"
            className="form-control"
            value={formatDateInputFriendly(localRfq.deliveryRequiredBy)}
            onChange={(e) => setLocalRfq({ ...localRfq, deliveryRequiredBy: e.target.value })}
            readOnly={!canEdit}
          />
        </div>
        <div className="form-group">
          <label>Special Instructions</label>
          <textarea
            className="form-control"
            value={localRfq.specialInstructions}
            onChange={(e) => setLocalRfq({ ...localRfq, specialInstructions: e.target.value })}
            readOnly={!canEdit}
          />
        </div>
        <div className="form-group">
          <label>Bcc Sender</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="checkbox"
            field="bccProcurementManager"
            value={localRfq.bccProcurementManager}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Recipients</label>
          <div className='' style={{width: 'calc(100% - 220px)'}}>
            {localRfq.recipients.length > 0 && (
              localRfq.recipients.map((r, idx) => (
                <div key={idx} className="recipient-item">
                  <div className="recipient-info" />
                  <span className="contact-name"><b>{r.contactName}</b> with </span>
                  <span className="company-name"><b>{r.companyName}</b> </span>
                  {r.sentTimestamp && (
                    <span className="sent-timestamp">Sent: {formatDateInputFriendly(r.sentTimestamp)}</span>
                  )}
                  <span className="btn btn-x-sm fas fa-pencil btn-background-hover" onClick={() => setEditingRecipientIdx(idx)} />
                  <span className="btn btn-x-sm fas fa-trash-alt btn-background-hover" onClick={() => _handleRemoveRecipient(r)} />
                </div>
              ))
            )}
            <button className="btn btn-x-sm btn-blue button-spacing" onClick={() => setShowRecipientModal(true)}>
              Add Recipient
            </button>

            {hasNewRecipients && (
              <div className="new-recipients-banner">
                Only new recipients will recieve this RFQ when you send it.
                Any recipients with a date value next to thier name have already recieved an email and will not recieve subsequent sends.
              </div>
            )}
            <style>
              {`
            .button-spacing {
              margin-bottom: 10px;
              }

            .new-recipients-banner {
            background-color: #f8d7da;
            color: #721c24;
            padding: 10px;
            border: 1px solid #f5c6cb;
            border-radius: 4px;
            margin: 10px 0px;
            font-size: 12px;
            }`
              }
            </style>


          </div>
        </div>
      </div>
      <div className="modal-footer">
        {canEdit && (
          <>
            <button className="btn btn-x-sm btn-outline-primary" onClick={() => handleSave(false)}>
              Save
            </button>
            <button className="btn btn-x-sm btn-outline-secondary" onClick={() => handleSave(true)}>
              Send
            </button>
          </>
        )}
        <button className="btn btn-x-sm btn-outline-secondary" onClick={close}>
          Close
        </button>
      </div>
      {
        (showRecipientModal || (editingRecipientIdx !== null)) && (

          <RFQRecipientModal
            r={editingRecipientIdx !== null ? rfq.recipients[editingRecipientIdx] : null}
            saveCallback={editingRecipientIdx ? _handleRecipientEdit : _handleAddRecipient}
            close={() => {
              setShowRecipientModal(false)
              setEditingRecipientIdx(null)
            }}
          />
        )
      }
    </Modal2>
  );
};

