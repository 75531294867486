import * as React from 'react';
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import {
  SubmittalPackage,
  SubmittalPackageStatus,
} from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import * as cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { submittalIsFinished } from '../helpers/misc';
import AddPackageModal from './AddPackageModal';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';

export const SubmittalPackageGrid = () => {
  const [packagesUnfiltered, setPackagesUnfiltered] = React.useState<
    SubmittalPackage[]
  >([]);
  const [packages, setPackages] = React.useState<SubmittalPackage[]>([]);
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [message, setMessage] = React.useState<string | undefined>(undefined);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const projectId = projectStore.projId;

  React.useEffect(() => {
    _getAll(projectId);
  }, [projectId]);

  React.useEffect(() => {
    fixHeaderWidth();
  }, [packages, showFilters]);

  const fixHeaderWidth = () => {
    const container = document.querySelector(
      '.submittal-grid'
    ) as HTMLDivElement;
    const headerRow = container.querySelector(
        '.submittal-grid-header'
      ) as HTMLDivElement,
      filterRow = container.querySelector(
        '.submittal-grid-filter'
      ) as HTMLDivElement,
      firstRow = container.querySelector('a.striped-row') as HTMLDivElement;
    if (firstRow != null) {
      const diff = headerRow.clientWidth - firstRow.clientWidth + 10;
      headerRow.style.width = firstRow.clientWidth + 10 + 'px';

      if (filterRow != null)
        filterRow.style.width = firstRow.clientWidth + 10 + 'px';
    }
  };

  const _getAll = (id: number) => {
    fetch(`api/SubmittalPackage/All?projectId=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setPackages(data);
          setPackagesUnfiltered(data);
          setLoading(false);
        } else {
          setMessage(data.message);
          setLoading(false);
        }
      });
  };

  const _add = (item: SubmittalPackage) => {
    const newItems = packagesUnfiltered.slice();
    newItems.unshift(item);

    setPackagesUnfiltered(newItems);
    setPackages(newItems);
    setShowAddModal(false);
  };

  const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value.toUpperCase();
    const prop = e.currentTarget.getAttribute('name') || '';
    const filtered = packagesUnfiltered.slice().filterByStringProp(prop, val);

    setPackages(filtered);
  };

  const _filterOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = e.currentTarget.querySelector(
      'option:checked'
    ) as HTMLOptionElement;
    const prop = e.currentTarget.getAttribute('name') || '';

    const filtered = packagesUnfiltered
      .slice()
      .filterByStringProp(prop, opt.value.toUpperCase());

    setPackages(filtered);
  };

  const _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
    const prop = e.currentTarget.getAttribute('data-for');
  };

  const _toggleFilters = () => setShowFilters(!showFilters);

  const _toggleAddModal = () => setShowAddModal(!showAddModal);

  const _clearMessage = () => setMessage(undefined);

  const renderGrid = (packages: SubmittalPackage[]) => {
    const rows = packages
      ? packages.map((x) => {
          let statusClass = '',
            statusText = '';
          switch (x.status) {
            case SubmittalPackageStatus.Created:
              statusClass = 'left-tab-yellow';
              statusText = 'Draft';
              break;
            case SubmittalPackageStatus.Sent:
              statusClass = 'left-tab-blue';
              statusText = 'Sent';
              break;
            case SubmittalPackageStatus.Complete:
              statusClass = 'left-tab-green';
              statusText = 'Complete';
              break;
          }

          return (
            <NavLink
              to={`/package/${x.id}`}
              key={x.id}
              className={cx('col-sm-12 striped-row', statusClass)}
            >
              <div className="col-sm-1 min-130">{x.packageNumber}</div>
              <div className="col-sm-2 min-220">{x.title}</div>
              <div className="col-sm-1 mobile-hide">{statusText}</div>
              <div className="col-sm-1 mobile-hide">{x.userFullName}</div>
              <div className="col-sm-1 mobile-hide">
                {x.submittals.filter((x) => !submittalIsFinished(x)).length}
              </div>
              <div className="col-sm-1 mobile-hide">{x.submittals.length}</div>
              <div className="col-sm-4 mobile-hide">
                {formatDate(x.completedTimestamp)}
              </div>
            </NavLink>
          );
        })
      : [];
    return (
      <div className="grid submittal-grid">
        <Loader loading={saving} />
        <MessageBox message={message} clearMessage={_clearMessage} />
        {renderGridHeader()}
        {showFilters && renderFilterRow()}
        {loading && (
          <div style={{ width: '100%' }}>
            <StandardGridLoader
              rowCount={14}
              rowPadding={14}
              rowContentHeight={18}
            />
          </div>
        )}
        <div className="grid-body custom-scrollbar">{rows}</div>
      </div>
    );
  };

  function renderGridHeader() {
    return (
      <div className="col-sm-12 submittal-grid-header row">
        <div className="col-sm-1 min-130 sortable">
          <label onClick={_sort} data-for="packageNumber">
            Package #
          </label>
        </div>
        <div className="col-sm-2 min-220">
          <label>Title</label>
        </div>
        <div className="col-sm-1 mobile-hide">
          <label>Status</label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="userFullName">
            Created By
          </label>
        </div>
        <div className="col-sm-1 mobile-hide">
          <label>Items Remaining</label>
        </div>
        <div className="col-sm-1 mobile-hide">
          <label>Total Items</label>
        </div>
        <div className="col-sm-4 mobile-hide">
          <label>Complete Date</label>
        </div>
        <div className="col-sm-1 min-110">
          <span
            className="btn btn-sm btn-blue fas fa-plus"
            onClick={_toggleAddModal}
          ></span>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-filter"
            onClick={_toggleFilters}
          ></span>
        </div>
      </div>
    );
  }

  function renderFilterRow() {
    return (
      <div className="col-sm-12 submittal-grid-filter">
        <div className="col-sm-1 min-130">
          <input
            name="packageNumber"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="col-sm-2 min-220">
          <input name="title" className="form-control" onChange={_filter} />
        </div>
        <div className="col-sm-1 mobile-hide">
          <select
            name="status"
            className="form-control"
            onChange={_filterOnSelect}
          >
            <option value="">Select...</option>
            <option value="0">Draft</option>
            <option value="1">Sent</option>
            <option value="2">Complete</option>
          </select>
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="userFullName"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="col-sm-offset-1 col-sm-1 mobile-hide">
          {/*<input name='approvedTimestamp' className='form-control' type='date' onChange={_filter} />*/}
        </div>
      </div>
    );
  }

  return (
    <div>
      {renderGrid(packages)}
      {showAddModal && (
        <AddPackageModal
          projectId={projectId}
          close={_toggleAddModal}
          addCallback={_add}
        />
      )}
    </div>
  );
};
