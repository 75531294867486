import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import * as cx from 'classnames';

import * as RFIStore from '../store/rfi';
import { ApplicationState } from '../store/index';
import { distinct } from '../helpers/formulas';
import Modal from '../components/Modal';
import { CustomFieldEntry, FilterOption, Submittal, SubmittalStatus } from '../interfaces/interfaces';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { MessageBox } from '../components/MessageBox';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { CustomFieldEntryComponent } from '../components/CustomFieldEntryComponent';

type Props = any;
//& RouteComponentProps<{ id: string }>

export const SubmittalCustomFieldsGrid = (props: any) => {
    const [selectedSubmittalId, setSelectedSubmittalId] = React.useState(null as number | null);
    const [showFilters, setShowFilters] = React.useState(false);
    const [filterOpts, setFilterOpts] = React.useState([] as FilterOption[])
    const [submittals, setSubmittals] = React.useState([] as Submittal[])
    const [customFields, setCustomFields] = React.useState([] as CustomFieldEntry[]);
    const [message, setMessage] = React.useState(undefined as string | undefined);
    const [loading, setLoading] = React.useState(true);
    const projectStore = useSelector((s: ApplicationState) => s.detail);
    const projectId = projectStore.projId;

    React.useEffect(() => {
        const loadData = async () => {
            fetch(`api/submittal/all?projectId=${projectId}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setSubmittals(data);
                    else setMessage(data.message);
                })
            fetch(`api/submittal/customfields?projectId=${projectId}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setCustomFields(data);
                    else setMessage(data.message);
                    setLoading(false)
                })
        };
        setLoading(true);
        loadData();
    }, [projectId]);

    const _updateField = (fieldId: number, value: string) => {        
        fetch(`api/submittal/customfield?projectId=${projectId}&fieldId=${fieldId}&value=${encodeURIComponent(value)}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setCustomFields(data)
                else setMessage(data.message);
            })
    }

    const _customFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const prop = e.currentTarget.getAttribute('name') || ''

        // First remove the old filter entry, if it existed
        const newOpts = [...filterOpts.filter(f => f.prop !== prop)]

        // Now that the old one is removed, we can add the filter on that field back, if it's not an empty string
        if (val !== '') {
            newOpts.push({ prop: prop, value: val } as FilterOption);
        }

        setFilterOpts(newOpts);
    }

    const _applyFilters = (rfis: Submittal[]) => {
        if (!customFields || customFields.length === 0 || filterOpts.length === 0) return rfis;

        const ids: number[][] = [];

        [].forEach.call(filterOpts, (f) => {
            //@ts-ignore
            if (f.prop === 'submittalNumber') {
                //@ts-ignore
                ids.push(submittals.filter(s => s.submittalNumber.toLowerCase().indexOf(f.value.toLowerCase()) !== -1).map(s => s.id));
            }
            else {
                //@ts-ignore
                const entries = customFields.filter(x => x.fieldName === f.prop && x.value.toString().toLowerCase().indexOf(f.value.toLowerCase()) !== -1);
                ids.push(entries.map(x => x.recordId));
            }
        })

        // We got all the ids that matched at least one filter, now we need to insersect all the arrays to get the ids in all arrays (i.e. matched all filters)
        const intersectedIds = ids.reduce((a, b) => b.filter(Set.prototype.has, new Set(a)));

        return rfis.filter(r => intersectedIds.findIndex(i => r.id === i) !== -1);
    }

    const SubmittalCustomFieldsModal = () => {
        const submittal = submittals.find(x => x.id === selectedSubmittalId);
        const recordFields = customFields.filter(f => f.recordId === selectedSubmittalId);
        const fields = recordFields ? recordFields.map(x => (
            <div className='custom-field-row' key={x.id}>
                <div className='col-sm-4'><label title={x.fieldName} className='truncate'>{x.fieldName}</label></div>
                <div className='col-sm-8'>
                    <CustomFieldEntryComponent entry={x} handleChange={_updateField} />                    
                </div>
            </div>
        )) : []

        return (
            <Modal>
                <div className='modal-header'>
                    <h5>{submittal ? submittal.submittalNumber : ''}</h5>
                </div>
                <div className='modal-body'>
                    {fields}
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => { setTimeout(() => { }, 100); setSelectedSubmittalId(null) }}>Done</button>
                </div>
            </Modal>
        )
    }

    const lines = _applyFilters(submittals).map(s => {
        let statusClass = '', statusText = '';
        switch (s.status) {
            case 0: statusClass = 'left-tab-yellow'; statusText = 'Draft'; break;
            case 1: statusClass = 'left-tab-blue'; statusText = 'Submitted'; break;
            case 2: statusClass = 'left-tab-green'; statusText = 'Reviewed'; break;
            case 7: statusClass = 'left-tab-purple'; statusText = 'Void'; break;
            case 4: statusClass = 'left-tab-yellow'; statusText = 'Passed to Vendor'; break;
            case 5: statusClass = 'left-tab-yellow'; statusText = 'Creator Review'; break;
            case SubmittalStatus.Rejected: statusClass = 'left-tab-red'; statusText = 'Rejected'; break;
        }
        const recordFields = customFields.filter(f => f.recordId === s.id);
        const fieldCells = recordFields ?
            recordFields.map(x => {
                //@ts-ignore
                return <div className='col-sm-1 truncate' title={x.value.toString()}>{x.value}</div>
            })
            : []

        return (
            <div style={{ display: 'flex' }} className={cx('col-sm-12 striped-row', statusClass)} key={s.id} onClick={() => setSelectedSubmittalId(s.id)}>
                <div className='col-sm-1 min-110'>{s.submittalNumber}</div>
                {fieldCells}
            </div>
        )
    })

    const headerFields = customFields.map(x => x.fieldName).filter(distinct);
    const headerCells = headerFields ? headerFields.map(x => <div className='col-sm-1 truncate' title={x} key={x}>{x}</div>) : []
    const header = (
        <div className='submittal-grid-header' style={{ paddingBottom: '7px' }}>
            <div className='col-sm-1 min-110'>Submittal #</div>
            {headerCells}
        </div>
    )

    const fieldFilterBoxes = headerFields ? headerFields.map(x => <div className='col-sm-1'><input name={x} className='form-control' onChange={_customFilter} /></div>) : []

    const filterLine = (
        <div className='submittal-grid-filter' style={{ display: 'flex', marginLeft: '6px' }}>
            <div className='col-sm-1 min-130'><input name='submittalNumber' className='form-control' onChange={_customFilter} /></div>
            {fieldFilterBoxes}
        </div>
    )

    return (
        <div style={{ position: 'relative' }}>
            <span className='btn btn-sm btn-outline-secondary fas fa-filter' onClick={() => setShowFilters(!showFilters)} style={{ position: 'absolute', top: '0px', right: '0px', fontSize: '11px' }}></span>
            <div className='grid submittal-grid' style={{ overflowX: 'auto' }}>
                <MessageBox message={message} clearMessage={() => setMessage(undefined)} />
                {header}
                {showFilters && filterLine}
                {loading && <div className='row' style={{ width: '100%' }}><StandardGridLoader rowCount={14} rowPadding={14} rowContentHeight={18} /></div>}
                <div className='grid-body custom-scrollbar' style={{ maxHeight: '60vh', overflowX: 'hidden' }}>
                    {lines}
                </div>
            </div>
            {selectedSubmittalId && SubmittalCustomFieldsModal()}
        </div>
    )
}