import * as React from 'react';
import { SubmittalGrid } from './SubmittalGrid';
import { Tabs, TabLink, TabContent } from '../tabs';
import { SubmittalPackageGrid } from './SubmittalPackageGrid';
import { KnownRoles } from '../auth/auth';
import { MyAuthElement } from '../auth/Authorization';
import { SubmittalCustomFieldsGrid } from './SubmittalCustomFieldsGrid';

export const SubmittalHome = () => {
  const [loading, setLoading] = React.useState(false);
  const activeTabStyle = {
    backgroundColor: 'rgb(0,99,66)',
    border: 'solid 0.5px rgb(0,56,119)',
    color: 'white',
  };

  const CsgComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
  ]);

  return (
    <div>
      <Tabs
        name="submittal-tabs"
        onChange={() => {}}
        renderActiveTabContentOnly={true}
        activeLinkStyle={activeTabStyle}
      >
        <div className="tab-links bordered">
          <TabLink to="items" component="span">
            <span>Items</span>
          </TabLink>
          <TabLink to="packages" component="span">
            <span>Packages</span>
          </TabLink>
          <CsgComp componentType="span">
            <TabLink to="customfields" component="span">
              <span>Custom Fields</span>
            </TabLink>
          </CsgComp>
        </div>
        <div className="content bordered">
          <TabContent for="items">
            <SubmittalGrid />
          </TabContent>
          <TabContent for="packages">
            <SubmittalPackageGrid />
          </TabContent>
          <TabContent for="customfields">
            <SubmittalCustomFieldsGrid />
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};
