import * as React from "react";
import {
	ChecklistStatus,
	ChecklistType,
	ListItem,
	ProjectTeamMember,
} from "../interfaces/interfaces";

import { ApplicationState } from "../store/index";
import { useSelector } from "react-redux";
import Modal from "../components/Modal";
import FilterableSelect from "../components/FilterableSelect";
import { ProjectRole } from "../config/global/ProjectRoles";
import { ChecklistGrid, ChecklistGridProps } from "../checklists/ChecklistGrid";
import { MessageBox } from "../components/MessageBox";

export const ProjectChecklistGrid = () => {
	const [templateOptions, setTemplateOptions] = React.useState(
		[] as ListItem[]
	);
	const [showAddModal, setShowAddModal] = React.useState(false);
	const [timestamp, setTimestamp] = React.useState(
		new Date().getMilliseconds()
	);
	const [selectedListRoleInfo, setSelectedListRoleInfo] = React.useState({
		restricted: false,
		roles: [] as ProjectRole[],
	});
	const [message, setMessage] = React.useState(undefined as string | undefined);

	const userStore = useSelector((s: ApplicationState) => s.user);
	const projectStore = useSelector((s: ApplicationState) => s.detail);
	const projectId = projectStore.projId;

	const _getListRoles = (id: string) => {
		fetch(`api/ChecklistConfig/GetListRoles?listId=${id}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setSelectedListRoleInfo(data);
			});
	};

	const _getUserChecklistTypes = () => {
		fetch(`api/ProjectChecklist/GetTemplates?recordId=${projectId}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setTemplateOptions(data);
			});
	};

	const _add = () => {
		const templateId = (
				document.querySelector(
					'#checklist-template input[name="checklist-template"]'
				) as HTMLInputElement
			).value,
			dueDate = (
				document.querySelector("#checklist-duedate") as HTMLInputElement
			).value;
		//const assigneeSelect = (document.querySelector('#assigned-to input[type="hidden"]') as HTMLInputElement);

		if (!templateId || templateId === "0") {
			alert("Please select a template from the list.");
			return;
		}

		fetch(
			`api/ProjectChecklist/Add?recordId=${projectId}&listId=${templateId}&dueDate=${dueDate}`,
			{ method: "POST" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setTimestamp(new Date().getMilliseconds());
				} else {
					setMessage(data.message);
				}
				setShowAddModal(false);
			});
	};

	const gridProps = {
		parentRecordId: projectId,
		renderTimestamp: timestamp,
		toggleAddModal: () => setShowAddModal(!showAddModal),
		type: ChecklistType.Project,
	} as ChecklistGridProps;

	React.useEffect(() => {
		_getUserChecklistTypes();
	}, [projectId]);

	const addModal = (
		<Modal>
			<div className="modal-header">
				<h5>Create Checklist</h5>
			</div>
			<div className="modal-body">
				<div className="form-group">
					<div className="inline-label">Checklist Template*</div>
					<FilterableSelect
						items={templateOptions}
						id="checklist-template"
						onChange={(id) => _getListRoles(id)}
					/>
				</div>
				<div className="form-group">
					<div className="inline-label">Due Date</div>
					<input id="checklist-duedate" className="form-control" type="date" />
				</div>
			</div>
			<div className="modal-footer">
				<div className="btn btn-sm btn-blue" onClick={_add}>
					Create
				</div>
				<div
					className="btn btn-sm btn-outline-secondary"
					onClick={() => setShowAddModal(false)}
				>
					Close
				</div>
			</div>
		</Modal>
	);

	return (
		<div className="project-checklists">
			<MessageBox
				message={message}
				clearMessage={() => setMessage(undefined)}
			/>
			<ChecklistGrid {...gridProps} />
			{showAddModal && addModal}
		</div>
	);
};
