import * as React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import * as DetailStore from '../store/projectDetail';
import { ApplicationState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { ListItem, MaterialEntryVm } from '../interfaces/interfaces';
import { MessageBox } from '../components/MessageBox';
import MaterialList from '../components/MaterialList';
import { Loader } from '../components/Loader';
import { GanttLabel, Gantt } from '../charts/Gantt';
import { MilestoneList } from '../components/MilestoneList';
import UserCard from '../components/UserCard';
import { FileDownloader } from '../components/FileDownloader';
import AlertRibbon from '../components/AlertRibbon';
import {
  MyAuthElement,
  NoAuthElement,
  RestrictedComp,
} from '../auth/Authorization';
import { KnownRoles, hasRole, isUserById } from '../auth/auth';
import * as UserStore from '../store/user';
import * as Dfr from '../store/dfr';
import { Tabs, TabLink, TabContent } from '../tabs';
import DfrList from '../dfr/DfrList';
import { RFIHome } from '../rfi/RFIHome';
import { SubmittalHome } from '../submittals/SubmittalHome';
import DashboardComponent from '../dashboard/DashboardComponent';
import ProjectTeamGrid from './ProjectTeamGrid';
import { ProjectNumberLoader } from '../loaders/ProjectDetailLoader';
import ProjectTaskGrid from './ProjectTaskGrid';
import ProjectVendorGrid from './ProjectVendorGrid';
import { ProjectChecklistGrid } from './ProjectChecklistGrid';
import { TransmittalGrid } from '../transmittals/TransmittalGrid';
import { ProcurementGrid } from '../procurement/ProcurementGrid';
import { actionCreators as detailActionCreators } from '../store/projectDetail';
import { actionCreators as globalActionCreators } from '../store/global';
import { ProjectDetail } from './ProjectDetail';
import { ProjectOverview } from './ProjectOverview';
import { TabGroup } from '../tabs/TabGroup';
import { PunchlistHome } from '../punchlist/PunchlistHome';
import { ConnectedFileExplorer } from 'src/components/ConnectedFileExplorer';
import { IssueHome } from 'src/issues/IssueHome';

interface State {
  showAttachments: boolean;
  attchType?: number;
  defaultTab?: string;
  editProjectNumber: boolean;
}

interface JoinedProps {
  detail: DetailStore.Store;
  user: UserStore.Store;
  dfr: Dfr.Store;
}

type Props = JoinedProps &
  typeof DetailStore.actionCreators &
  RouteComponentProps<{ id: string }>;

//class DetailView extends React.Component<Props, State> {
export const ProjectHome = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useDispatch();
  const userStore = useSelector((s: ApplicationState) => s.user);
  const detailStore = useSelector((s: ApplicationState) => s.detail);
  const dfrStore = useSelector((s: ApplicationState) => s.dfr);
  detailStore.detail.teamMembers;
  const CsgComponent = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
    KnownRoles.SiteSuper,
  ]);
  const NoVendorComponent = NoAuthElement([
    KnownRoles.Vendor,
    KnownRoles.OwnerLtd,
  ]);
  const NoOwnerComponent = NoAuthElement([KnownRoles.Owner]);
  const NoOwnerLtdComponent = NoAuthElement([KnownRoles.OwnerLtd]);
  const isVendor = hasRole(userStore.user, [KnownRoles.Vendor]);
  const isOwner = hasRole(userStore.user, [KnownRoles.Owner]);
  const isSiteSuper = hasRole(userStore.user, [KnownRoles.SiteSuper]);
  const location = useLocation();

  const [state, setState] = React.useState({
    showAttachments: false,
    defaultTab: props.location.hash.substr(1),
    editProjectNumber: false,
  } as State);

  React.useEffect(() => {
    const pId = parseInt(props.match.params.id) || 0;
    _updateRecentProjectAccess(pId);
    dispatch(detailActionCreators.getDetail(pId));

    return function cleanup() {
      dispatch(detailActionCreators.clear());
    };
  }, [location]);

  const _updateRecentProjectAccess = (id: number) => {
    fetch(`api/project/updaterecent?id=${id}`, { method: 'POST' })
      .then((res) => Promise.all([res.ok]))
      .then(([resOk]) => {
        setTimeout(() => {
          dispatch(globalActionCreators.getRecentProjects());
        }, 2000);
      });
  };

  const _saveProjectNumber = () => {
    const newNumber = (
      document.getElementById('project-number-input') as HTMLInputElement
    ).value;
    dispatch(detailActionCreators.updateField('projectNumber', newNumber));
    setState({ ...state, editProjectNumber: false });
  };

  const renderScopeGantt = (materials: MaterialEntryVm[]) => {
    const chartData =
      materials && materials.length
        ? materials
            .sort((a, b) => (new Date(a.poDate) > new Date(b.poDate) ? 1 : -1))
            .filter(
              (m) =>
                m.dueDate && m.dueDate.length && m.poDate && m.poDate.length
            )
            .map((m) => {
              //const duration = (new Date(m.dueDate).getTime() - new Date(m.poDate).getTime());
              const taskName =
                (m.type == '1' ? 'Order ' : 'Install ') + m.materialName;
              const dep: string | null = null;
              //const depIdx = materials.findIndex(i => i.materialName == m.materialName && i.type == '1')
              //if (m.type == '2' && depIdx !== -1) {
              //    dep = materials[depIdx].entryId.toString()
              //}
              let schedule =
                (!m.complete || !m.complete.length) &&
                new Date(m.dueDate) <= new Date()
                  ? 'Late'
                  : 'On Time';
              if (m.outOfRange) schedule = 'Out Of Range';
              return [
                m.entryId.toString(),
                taskName,
                schedule,
                new Date(m.poDate),
                new Date(m.dueDate),
                null,
                m.progress,
                dep,
              ];
            })
        : [];

    return chartData.length ? (
      <Gantt
        columns={ganttColumns}
        tasks={chartData}
        graphId="scopeItem_gantt"
      />
    ) : (
      []
    );
  };

  const { detail, isLoading } = detailStore,
    { user } = userStore;
  const PmAdminComponent = MyAuthElement(
    [KnownRoles.Admin],
    [detail.internalPmId]
  );
  const PmSuperComponent = MyAuthElement(
    [KnownRoles.Admin, KnownRoles.CoreSuperUser],
    [detail.internalPmId]
  );
  const PmComponent = MyAuthElement([], [detail.internalPmId]);
  const milestoneSection = detail.id ? (
    //@ts-ignore
    <MilestoneList
      projectId={detail.id}
      projectNumber={detail.projectNumber}
      clientName={detail.clientName}
      disabled={isVendor}
      showGantt={true}
    />
  ) : (
    []
  );
  const materialSection = (
    //@ts-ignore
    <MaterialList vendors={detailStore.vendors} disabled={isVendor} />
  );
  const alertRibbon = <AlertRibbon alerts={detail.alerts} />;

  const scopeGantt =
    detail.materials && detail.materials.length
      ? renderScopeGantt(detail.materials)
      : [];
  const headerText =
    detail.name && detail.name.length
      ? `${detail.projectNumber} - ${detail.name}`
      : detail.projectNumber;
  const header = isLoading ? (
    <ProjectNumberLoader />
  ) : state.editProjectNumber ? (
    <input
      id="project-number-input"
      className="form-control"
      defaultValue={detail.projectNumber}
    />
  ) : (
    headerText
  );

  return (
    <div>
      <MessageBox
        message={detailStore.message}
        clearMessage={() => detailActionCreators.clearMessage}
      />
      <Loader loading={detailStore.isSaving} />
      <div className="pageHeader">
        {header}
        <PmSuperComponent class="inline-block">
          {state.editProjectNumber ? (
            <div>
              <button
                className="btn btn-sm btn-blue"
                onClick={_saveProjectNumber}
                style={{ marginRight: '5px' }}
              >
                Save
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setState({ ...state, editProjectNumber: false })}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div>
              <span
                className="fas fa-edit"
                onClick={() => setState({ ...state, editProjectNumber: true })}
              ></span>
            </div>
          )}
        </PmSuperComponent>
        <div
          style={{
            position: 'absolute',
            right: '15px',
            top: '4px',
            display: 'flex',
          }}
        >
          {/*<PauseLogAlert log={detail.latestPause} />*/}
          {/*<PmComponent>*/}
          {/*  {detail.latestPause == undefined ? (*/}
          {/*    <ProjectPauser pause={detailActionCreators.pause} />*/}
          {/*  ) : (*/}
          {/*    <ProjectResumer resume={detailActionCreators.resume} />*/}
          {/*  )}*/}
          {/*</PmComponent>*/}
          {/*<PmAdminComponent>*/}
          {/*  {detail.closeoutStarted ? (*/}
          {/*    []*/}
          {/*  ) : (*/}
          {/*    <button*/}
          {/*      className="btn btn-sm btn-outline-secondary mobile-hide"*/}
          {/*      style={{ marginLeft: '10px' }}*/}
          {/*      onClick={detailActionCreators.beginCloseout}*/}
          {/*    >*/}
          {/*      Begin Closeout*/}
          {/*    </button>*/}
          {/*  )}*/}
          {/*</PmAdminComponent>*/}
        </div>
      </div>
      <Tabs
        name="admin-tabs"
        onChange={(t: string) => {}}
        defaultTab={user ? user.defaultProjectTab : 'overview'}
        renderActiveTabContentOnly={true}
      >
        <div className="tab-groups">
          <TabGroup
            groupName="records"
            groupText={
              <span>
                <span className="fa-solid fa-aperture"></span> &nbsp;
              </span>
            }
          >
            <TabLink
              to="tasks"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Tasks</span>
            </TabLink>
            <NoOwnerLtdComponent
              componentType="span"
              //roles={[KnownRoles.Admin, KnownRoles.CoreSuperUser, KnownRoles.SiteSuper, KnownRoles.CSGPM]} allowedIds='' subs={[]}
            >
              <TabLink
                to="dfr"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>Field Reports</span>
              </TabLink>
            </NoOwnerLtdComponent>
            <NoVendorComponent componentType="span">
              <TabLink
                to="checklists"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>Checklists</span>
              </TabLink>
            </NoVendorComponent>
            <TabLink
              to="punchlist"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Punchlist</span>
            </TabLink>
            <TabLink
              to="rfi"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>RFIs</span>
            </TabLink>
            <TabLink
              to="submittals"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Submittals</span>
            </TabLink>

            <CsgComponent componentType="span">
              <TabLink
                to="procurement"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>Procurement</span>
              </TabLink>
            </CsgComponent>
            <TabLink
              to="transmittals"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Transmittals</span>
            </TabLink>
            <TabLink
              to="issues"
              component="span"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Issues</span>
            </TabLink>
          </TabGroup>
          <TabGroup
            groupName="management"
            groupText={
              <span>
                <span className="fas fa-tools"></span> &nbsp;
              </span>
            }
          >
            <TabLink
              to="overview"
              component="div"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Overview</span>
            </TabLink>
            <TabLink
              to="detail"
              component="div"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Detail</span>
            </TabLink>
            <TabLink
              to="team"
              component="div"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Team</span>
            </TabLink>
            <TabLink
              to="scheduling"
              component="div"
              activeStyle={{ fontWeight: 'bold' }}
            >
              <span>Scheduling</span>
            </TabLink>
            <CsgComponent componentType="span">
              <TabLink
                to="subs"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>Subcontractors</span>
              </TabLink>
            </CsgComponent>
            <NoOwnerLtdComponent componentType="span">
              <TabLink
                to="explorer"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>File Explorer</span>
              </TabLink>
            </NoOwnerLtdComponent>
            <CsgComponent componentType="span">
              <TabLink
                to="activity"
                component="span"
                activeStyle={{ fontWeight: 'bold' }}
              >
                <span>Activity</span>
              </TabLink>
            </CsgComponent>
          </TabGroup>
        </div>
        <div className="content" style={{ minHeight: '50vh' }}>
          <TabContent for="overview">
            <NoVendorComponent>{alertRibbon}</NoVendorComponent>
            <ProjectOverview />
          </TabContent>
          <TabContent for="detail">
            <ProjectDetail detail={detail} />
          </TabContent>
          <TabContent for="scheduling">
            <div
              style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 215px)' }}
              className="custom-scrollbar"
            >
              <div className="section project-milestones">
                <h4>Milestones</h4>
                {milestoneSection}
              </div>
              {/*
                                //@ts-ignore */}
              <NoOwnerComponent>
                <div className="section project-materials">
                  <h4>
                    Scope
                    <FileDownloader
                      actionPath={`api/Report/ScopeExcel`}
                      fileName={detail.projectNumber}
                      fileType="XLSX"
                      queryParams={{ id: detail.id }}
                    />
                  </h4>
                  {materialSection}
                  <div className="section scope-gantt mobile-hide">
                    {scopeGantt}
                  </div>
                </div>
              </NoOwnerComponent>
            </div>
          </TabContent>
          <TabContent for="team">
            <ProjectTeamGrid projectId={detail.id} pmId={detail.internalPmId} />
          </TabContent>
          <TabContent for="tasks">
            <ProjectTaskGrid projectId={detail.id} clientId={detail.clientId} />
          </TabContent>
          <TabContent for="dfr">
            <div className="col-sm-12">
              {/*
                                //@ts-ignore */}
              <DfrList
                projectTeamMembers={detailStore.detail.teamMembers?.map(
                  (x) => new ListItem(x.userId, x.userFullName)
                )}
                projectId={parseInt(props.match.params.id) || 0}
              />
            </div>
          </TabContent>
          <TabContent for="checklists">
            <div className="col-sm-12">
              <ProjectChecklistGrid  />
            </div>
          </TabContent>
          <TabContent for="punchlist">
            <div className="">
              <PunchlistHome />
            </div>
          </TabContent>
          <TabContent for="rfi">
            <div className="col-sm-12">
              <RFIHome />
            </div>
          </TabContent>
          <TabContent for="submittals">
            <div className="col-sm-12 no-pad">
              <SubmittalHome />
            </div>
          </TabContent>
          {/*
                        //@ts-ignore */}
          <CsgComponent>
            <TabContent for="subs">
              <div className="col-sm-12 no-pad">
                <ProjectVendorGrid projectId={detail.id} />
              </div>
            </TabContent>
          </CsgComponent>
          {/*
                        //@ts-ignore */}
          <CsgComponent>
            <TabContent for="procurement">
              <ProcurementGrid project={detail} />
            </TabContent>
          </CsgComponent>
          <TabContent for="transmittals">
            <div className="col-sm-12">
              <TransmittalGrid key={detail.id} projectId={detail.id} />
            </div>
          </TabContent>
          <NoOwnerLtdComponent>
            <TabContent for="explorer">
              <div className="col-sm-12">
                <ConnectedFileExplorer
                  key={detail.id}
                  startingDir={`Clients/${detail.clientId}/Projects/${detail.id}`}
                  breadCrumbStartLevel={4}
                  canSubscribeUsers={isUserById(user, [detail.internalPmId])}
                  params={{ id: detail.id }}
                  droppableId={'project-home-droppable'}
                  draggableId={'project-home-draggable'}
                />
              </div>
            </TabContent>
          </NoOwnerLtdComponent>
          {/*
                        //@ts-ignore */}
          <CsgComponent>
            <TabContent for="activity">
              <DashboardComponent projectId={detail.id} />
            </TabContent>
          </CsgComponent>
          <TabContent for="issues">
            <div>
              <IssueHome />
            </div>
          </TabContent>
        </div>
      </Tabs>
    </div>
  );
};

const ganttColumns = [
  { type: 'string', label: 'Id' },
  { type: 'string', label: 'Name' },
  { type: 'string', label: 'Schedule' },
  { type: 'date', label: 'Start Date' },
  { type: 'date', label: 'Due Date' },
  { type: 'number', label: 'Duration' },
  { type: 'number', label: 'Percent Complete' },
  { type: 'string', label: 'Dependencies' },
] as GanttLabel[];

//@ts-ignore
//export default connect(
//    (state: ApplicationState) => {
//        return { detail: state.detail, user: state.user, dfr: state.dfr }
//        //return { state.detail | state.user }
//    },
//    DetailStore.actionCreators
//    // @ts-ignore
//)(DetailView) as typeof DetailView
