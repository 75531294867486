import * as React from 'react';
import { ApplicationState } from '../store';
import { connect, useSelector } from 'react-redux';
import { MessageBox } from '../components/MessageBox';
import * as RFIStore from '../store/rfi';
import { Loader } from '../components/Loader';
import {
  RFI,
  RFIQuestionDocument,
  RFIStatus,
  UserProjectNotifications,
} from '../interfaces/interfaces';
import RFIAddModal from './RFIAddModal';
import { formatDate } from '../helpers/formatters';
import * as cx from 'classnames/index';
import { NavLink } from 'react-router-dom';
import { FileDownloader } from '../components/FileDownloader';
import Modal from '../components/Modal';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { ReissueRequestGrid } from './ReissueRequestGrid';
import { Tabs, TabContent, TabLink } from '../tabs/index';
import RFICustomFieldsGrid from './RFICustomFieldsGrid';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import DocIcon from '../components/DocIcon';
import { actionCreators as RFIActions } from '../store/rfi';
import { useDispatch } from 'react-redux';

export const RFIHome = () => {
  const [showFilters, setShowFilters] = React.useState(false);
  const [showReportModal, setShowReportModal] = React.useState(false);
  const [userNotifications, setUserNotifications] =
    React.useState<UserProjectNotifications>({} as UserProjectNotifications);
  const [sortOrder, setSortOrder] = React.useState('DESC');
  const [sortProp, setSortProp] = React.useState<string | undefined>(undefined);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const rfiStore = useSelector((s: ApplicationState) => s.rfi);
  const showRFIForm = rfiStore.showRFIForm;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(RFIActions.request(projectStore.projId));
    _getUserNotifications(projectStore.projId);

    return () => {
      dispatch(RFIActions.clearMessage());
    };
  }, [projectStore.projId]);

  const CsgComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
  ]);

  const _getUserNotifications = (id: number) => {
    fetch(`api/ProjectNotification/Get?id=${id}`)
      .then((res) => res.json())
      .then((data) => setUserNotifications(data));
  };

  const _toggleWatchNotifications = () => {
    fetch(`api/ProjectNotification/RFIs?id=${rfiStore.projectId}`, {
      method: 'PUT',
    })
      .then((res) => res.json())
      .then((data) => setUserNotifications(data));
  };

  const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const prop = e.currentTarget.getAttribute('name') || '';
    dispatch(RFIActions.filter(prop, val));
  };

  const _filterOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = e.currentTarget.querySelector(
      'option:checked'
    ) as HTMLOptionElement;
    const prop = e.currentTarget.getAttribute('name') || '';
    dispatch(RFIActions.filter(prop, opt.value));
  };

  const _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
    const prop = e.currentTarget.getAttribute('data-for') || undefined;
    const sort = sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setSortProp(prop);
    setSortOrder(sort);
  };

  const _toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const _toggleReportModal = () => {
    setShowReportModal(!showReportModal);
  };

  let rfis = rfiStore.rfis;
  if (sortProp) {
    rfis = rfis.sort((a, b) => {
      let aVal = a,
        bVal = b;
      sortProp.split('.').forEach((x) => {
        (aVal = aVal[x]), (bVal = bVal[x]);
      });
      if (bVal === undefined) return -1;
      if (sortOrder === 'DESC') return aVal > bVal ? -1 : 1;
      else return aVal > bVal ? 1 : -1;
    });
  }

  const renderGridHeader = () => {
    const sortIconClass =
      sortOrder === 'DESC'
        ? 'sort-icon fas fa-arrow-down'
        : 'sort-icon fas fa-arrow-up';
    const sortIcon = sortProp ? <span className={sortIconClass}></span> : [];
    return (
      <div className="col-sm-12 rfi-grid-header">
        <div className="col-sm-1 min-110 sortable">
          <label onClick={_sort} data-for="rfiNumber">
            RFI # {sortProp === 'rfiNumber' && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="date">
            Submit Date {sortProp === 'date' && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="dueDate">
            Due Date {sortProp === 'dueDate' && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="status">
            Status {sortProp === 'status' && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="requesterName">
            Submitted By {sortProp === 'requesterName' && sortIcon}
          </label>
        </div>
        <div className="col-sm-2 mobile-hide sortable">
          <label onClick={_sort} data-for="reviewers.userFullName">
            Reviewer(s) {sortProp === 'reviewers.userFullName' && sortIcon}
          </label>
        </div>
        <div className="col-sm-2 min-85">
          <label>Subject</label>
        </div>
        <div className="col-sm-1 mobile-hide">
          <label>Documents</label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={_sort} data-for="approvedTimestamp">
            Accepted Date {sortProp === 'approvedTimestamp' && sortIcon}
          </label>
        </div>
        <div
          className="col-sm-1 min-130"
          style={{
            display: 'flex',
            position: 'absolute',
            right: '0px',
            top: '5px',
          }}
        >
          <span
            className="btn btn-sm btn-blue fas fa-plus"
            onClick={() => dispatch(RFIActions.toggleRFIForm())}
          ></span>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-filter"
            onClick={_toggleFilters}
          ></span>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-download"
            title="Download Pdf"
            onClick={_toggleReportModal}
          ></span>
          <span
            className={cx(
              'btn btn-sm btn-outline-secondary far',
              userNotifications.watchRFIs ? 'fa-eye-slash' : 'fa-eye'
            )}
            title={
              userNotifications.watchRFIs
                ? 'Stop automatically being CCed on RFIs for this project'
                : 'Automatically be CCed on all RFIs on this project'
            }
            onClick={_toggleWatchNotifications}
            style={{ fontSize: '11px' }}
          ></span>
        </div>
      </div>
    );
  };

  const renderGrid = (items: RFI[]) => {
    const rows = items.length
      ? items.map((x) => {
          const reviewers =
            x.reviewers && x.reviewers.length
              ? x.reviewers.map((x) => x.userFullName).join(', ')
              : '';
          let statusClass = '',
            statusText = '';
        switch (x.status) {
          case RFIStatus.Draft: statusClass = 'left-tab-yellow'; statusText = 'Draft'; break;
          case RFIStatus.AwaitingResponse: statusClass = 'left-tab-yellow'; statusText = 'Awaiting Response'; break;
          case RFIStatus.Answered: statusClass = 'left-tab-blue'; statusText = 'Answered'; break;          
          case RFIStatus.Accepted: statusClass = 'left-tab-green'; statusText = 'Accepted'; break;
          case RFIStatus.Reissued: statusText = 'Reissued'; statusClass = 'left-tab-purple'; break;
          case RFIStatus.ReissueRequested: statusText = 'Reissue Requested'; statusClass = 'left-tab-purple'; break;
          case RFIStatus.Canceled: statusText = 'Canceled'; statusClass = 'left-tab-red'; break;
        }
          const docs =
            x.questionDocuments && x.questionDocuments.length
              ? x.questionDocuments.map((doc) => (
                  <DocIcon key={x.id} doc={doc} />
                ))
              : [];

          return (
            <div
              key={x.id}
              className={cx('col-sm-12 striped-row', statusClass)}
            >
              <NavLink to={`/rfi/${x.id}`} className="row">
                <div className="col-sm-1 min-110">
                  {x.rfiNumber}{' '}
                  {x.late ? (
                    <span
                      title="This RFI is past due"
                      className="fas fa-exclamation-triangle"
                    ></span>
                  ) : (
                    []
                  )}
                </div>
                <div className="col-sm-1 mobile-hide">{formatDate(x.date)}</div>
                <div className="col-sm-1 mobile-hide">
                  {formatDate(x.dueDate)}
                </div>
                <div className="col-sm-1 mobile-hide">{statusText}</div>
                <div className="col-sm-1 mobile-hide">{x.userFullName}</div>
                <div className="col-sm-2 mobile-hide">{reviewers}</div>
                <div className="col-sm-2 min-179">{x.subject}</div>
                <div className="col-sm-1 mobile-hide">
                  <div style={{ display: 'flex' }}>{docs}</div>
                </div>
                <div className="col-sm-2 mobile-hide">
                  {formatDate(x.approvedTimestamp)}
                </div>
              </NavLink>
            </div>
          );
        })
      : [];

    return (
      <div className="grid rfi-grid">
        {renderGridHeader()}
        {showFilters && renderFilterLine()}
        {rfiStore.loading && (
          <div className="">
            <StandardGridLoader rowContentHeight={20} rowPadding={21} />
          </div>
        )}
        <div className="grid-body custom-scrollbar">{rows}</div>
      </div>
    );
  };

  const renderFilterLine = () => {
    return (
      <div className="col-sm-12 rfi-grid-filter" style={{ display: 'flex' }}>
        <div className="col-sm-1 min-130">
          <input name="rfiNumber" className="form-control" onChange={_filter} />
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="date"
            className="form-control"
            type="date"
            onChange={_filter}
          />
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="dueDate"
            className="form-control"
            type="date"
            onChange={_filter}
          />
        </div>
        <div className="col-sm-1 mobile-hide">
          <select
            name="status"
            className="form-control"
            onChange={_filterOnSelect}
          >
            <option value="">Select...</option>
            <option value="0">Draft</option>
            <option value="1">Awaiting Response</option>
            <option value="2">Answered</option>
            <option value="4">Accepted</option>
            <option value="5">Reissued</option>
            <option value="6">Reissue Requested</option>
          </select>
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="requesterName"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="col-sm-2 mobile-hide">
          <input name="reviewers" className="form-control" onChange={_filter} />
        </div>
        <div className="col-sm-2 min-220">
          <input name="subject" className="form-control" onChange={_filter} />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="approvedTimestamp"
            className="form-control"
            type="date"
            onChange={_filter}
          />
        </div>
      </div>
    );
  };

  const rfiGrid = renderGrid(rfiStore.rfis);

  return (
    <div>
      <Loader loading={rfiStore.saving} />
      <MessageBox
        message={rfiStore.message}
        clearMessage={() => dispatch(RFIActions.clearMessage())}
      />
      {showReportModal && (
        <ReportModal
          projectId={rfiStore.projectId}
          close={_toggleReportModal}
        />
      )}
      <ReissueRequestGrid projectId={rfiStore.projectId} />
      <div style={{ marginLeft: '-15px' }}>
        <Tabs
          name="rfi-tabs"
          onChange={() => {}}
          renderActiveTabContentOnly={true}
          activeLinkStyle={{
            backgroundColor: 'rgb(0,99,66)',
            border: 'solid 0.5px rgb(0,56,119)',
            color: 'white',
          }}
        >
          <div className="tab-links bordered">
            <TabLink to="items" component="span">
              <span>Items</span>
            </TabLink>
            <CsgComp componentType="span">
              <TabLink to="customfields" component="span">
                <span>Custom Fields</span>
              </TabLink>
            </CsgComp>
          </div>
          <div className="content bordered">
            <TabContent for="items">{rfiGrid}</TabContent>
            <TabContent for="customfields">
              <RFICustomFieldsGrid />
            </TabContent>
          </div>
        </Tabs>
      </div>
      {showRFIForm && (
        <RFIAddModal
          type="RFI"
          save={(m, f) => dispatch(RFIActions.addRFI(m, f))}
          close={() => dispatch(RFIActions.toggleRFIForm())}
          projectId={rfiStore.projectId}
        />
      )}
    </div>
  );
};

const ReportModal = (props: { projectId: number; close: () => void }) => {
  const [form, setForm] = React.useState({
    startDate: '',
    endDate: '',
    valid: false,
  });

  React.useEffect(() => {
    if (
      form.startDate &&
      form.endDate &&
      new Date(form.startDate) <= new Date(form.endDate)
    ) {
      if (!form.valid) setForm({ ...form, valid: true });
    } else {
      if (form.valid) setForm({ ...form, valid: false });
    }
  }, [form.startDate, form.endDate]);

  return (
    <Modal>
      <div className="modal-header">
        <h4>RFI Reports</h4>
      </div>
      <div className="modal-body">
        <b>Compiled RFI Printouts (PDF)</b>
        <p>
          <small>Select a date range to pull RFIs for</small>
        </p>
        <div style={{ display: 'flex' }}>
          <div
            className="form-group"
            style={{ width: '50%', paddingRight: '20px' }}
          >
            <label>Start Date*</label>
            <input
              type="date"
              className="form-control"
              value={form.startDate}
              onChange={(e) =>
                setForm({ ...form, startDate: e.currentTarget.value })
              }
            />
          </div>
          <div className="form-group" style={{ width: '50%' }}>
            <label>End Date*</label>
            <input
              type="date"
              className="form-control"
              value={form.endDate}
              onChange={(e) =>
                setForm({ ...form, endDate: e.currentTarget.value })
              }
            />
          </div>
        </div>
        {form.valid && (
          <FileDownloader
            actionPath="api/report/rfirangepdf"
            queryParams={{
              id: props.projectId,
              start: form.startDate,
              end: form.endDate,
            }}
            fileType="PDF"
          />
        )}
        <hr style={{ borderColor: 'gray' }} />
        <b>Logs (Excel)</b> &nbsp;
        <br />
        {/*<p><small>Saves to the project's 'Logs' folder</small></p>*/}
        <FileDownloader
          actionPath="api/rfi/log"
          queryParams={{ id: props.projectId }}
          fileType="Excel Log"
        />
      </div>
      <div className="modal-footer">
        <button
          style={{ marginLeft: '1%' }}
          className="btn btn-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
