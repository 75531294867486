import * as React from 'react';
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import {
  Submittal,
  SubmittalDocument,
  IDocumentInfo,
  SubmittalStatus,
  UserProjectNotifications,
} from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import * as cx from 'classnames';
import { NavLink } from 'react-router-dom';
import AddSubmittalModal from './AddSubmittalModal';
import DocIcon from '../components/DocIcon';
import {
  FileDownloader,
  downloadFileTask,
  getFileName,
} from '../components/FileDownloader';
import Modal from '../components/Modal';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';

interface Props {
  forPackage?: boolean;
}

interface State {
  projectId: number;
  itemsUnfiltered: Submittal[];
  submittals: Submittal[];
  showFilters: boolean;
  showAddModal: boolean;
  showReportModal?: boolean;
  sortProp?: string;
  sortOrder: string;
  loading: boolean;
  userNotifications: UserProjectNotifications;
  saving: boolean;
  message?: string;
}

export const SubmittalGrid = (props: Props) => {
  const [state, setState] = React.useState({
    projectId: 0,
    itemsUnfiltered: [],
    submittals: [],
    showAddModal: false,
    showFilters: false,
    sortOrder: 'DESC',
    loading: true,
    saving: false,
    userNotifications: {} as UserProjectNotifications,
  } as State);

  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const projectId = projectStore.projId;

  React.useEffect(() => {

    _getAll(projectId);
    _getUserNotifications(projectId);

    return () => _clearMessage();
  }, [projectId]);

  React.useEffect(() => {
    if (state.showFilters) {
      fixHeaderWidth();
    }
  }, [state.submittals]);

  const fixHeaderWidth = () => {
    const container = document.querySelector(
      '.submittal-grid'
    ) as HTMLDivElement;
    const headerRow = container.querySelector(
        '.submittal-grid-header'
      ) as HTMLDivElement,
      filterRow = container.querySelector(
        '.submittal-grid-filter'
      ) as HTMLDivElement,
      firstRow = container.querySelector('a.striped-row') as HTMLDivElement;
    if (firstRow != null) {
      const diff = headerRow.clientWidth - firstRow.clientWidth + 10;
      headerRow.style.width = firstRow.clientWidth + 10 + 'px';

      if (filterRow != null)
        filterRow.style.width = firstRow.clientWidth + 10 + 'px';
    }
  };

  const _getUserNotifications = (id: number) => {
    fetch(`api/ProjectNotification/Get?id=${id}`)
      .then((res) => res.json())
      .then((data) =>
        setState((prevState) => ({ ...prevState, userNotifications: data }))
      );
  };

  const _toggleWatchNotifications = () => {
    fetch(`api/ProjectNotification/Submittals?id=${state.projectId}`, {
      method: 'PUT',
    })
      .then((res) => res.json())
      .then((data) => setState({ ...state, userNotifications: data }));
  };

  const _getAll = (id: number) => {
    const url = props.forPackage
      ? `api/Submittal/ForPackage?packageId=${id}`
      : `api/Submittal/All?projectId=${id}`;
    fetch(url)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setState((prevState) => ({
            ...prevState,
            projectId: id,
            submittals: data,
            itemsUnfiltered: data,
            loading: false,
          }));
        } else
          setState((prevState) => ({
            ...prevState,
            projectId: id,
            message: data.message,
            loading: false,
          }));
      });
  };

  const _add = (item: Submittal) => {
    const newItems = state.itemsUnfiltered.slice();
    newItems.unshift(item);

    setState({
      ...state,
      itemsUnfiltered: newItems,
      submittals: newItems,
      showAddModal: false,
    });
  };

  const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value.toUpperCase();
    const prop = e.currentTarget.getAttribute('name') || '';
    const filtered = state.itemsUnfiltered
      .slice()
      .filterByNestedProp(prop, val);

    setState({ ...state, submittals: filtered });
  };

  const _filterOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = e.currentTarget.querySelector(
      'option:checked'
    ) as HTMLOptionElement;
    const prop = e.currentTarget.getAttribute('name') || '';

    const filtered = state.itemsUnfiltered
      .slice()
      .filterByStringProp(prop, opt.value.toUpperCase());

    setState({ ...state, submittals: filtered });
  };

  const _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
    const prop = e.currentTarget.getAttribute('data-for') || undefined;
    const sortOrder = state.sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setState({ ...state, sortProp: prop, sortOrder: sortOrder });
  };

  const _toggleFilters = () =>
    setState({ ...state, showFilters: !state.showFilters });

  const _toggleAddModal = () =>
    setState({ ...state, showAddModal: !state.showAddModal });

  const _clearMessage = () => setState({ ...state, message: undefined });

  const _toggleReportModal = () =>
    setState({ ...state, showReportModal: !state.showReportModal });

  const renderGrid = (submittals: Submittal[]) => {
    const rows = submittals
      ? submittals.map((x) => {
          let statusClass = '',
            statusText = '';
          switch (x.status) {
            case 0:
              statusClass = 'left-tab-yellow';
              statusText = 'Draft';
              break;
            case 1:
              statusClass = 'left-tab-blue';
              statusText = 'Submitted';
              break;
            case 2:
              statusClass = 'left-tab-green';
              statusText = 'Reviewed';
              break;
            case 7:
              statusClass = 'left-tab-purple';
              statusText = 'Void';
              break;
            case 4:
              statusClass = 'left-tab-yellow';
              statusText = 'Passed to Vendor';
              break;
            case 5:
              statusClass = 'left-tab-yellow';
              statusText = 'Creator Review';
              break;
            case SubmittalStatus.Rejected:
              statusClass = 'left-tab-red';
              statusText = 'Rejected';
              break;
          }

          return (
            <NavLink
              key={x.id}
              to={`/submittal/${x.id}`}
              className={cx('col-sm-12 striped-row', statusClass)}
            >
              <div className="my-col-2">
                {x.submittalNumber}{' '}
                {x.late ? (
                  <span
                    title="This submittal is past due"
                    className="fas fa-exclamation-triangle"
                  ></span>
                ) : (
                  []
                )}
              </div>
              <div className="my-col-3 truncate" title={x.title}>
                {x.title}
              </div>
              <div
                className="my-col-2 mobile-hide truncate"
                title={x.division.name}
              >
                {x.division.name}
              </div>
              <div
                className="my-col-3 mobile-hide truncate"
                title={x.subDivision?.name}
              >
                {x.subDivision?.name}
              </div>
              <div className="my-col-1 mobile-hide">{formatDate(x.date)}</div>
              <div className="my-col-1 mobile-hide">
                {formatDate(x.dueDate)}
              </div>
              <div className="my-col-2 mobile-hide">{statusText}</div>
              <div className="my-col-2 mobile-hide">{x.userFullName}</div>
              <div className="my-col-2 mobile-hide">{x.reviewerName}</div>
              {/*<div className='my-col-2 mobile-hide'>{formatDate(x.approvalTimestamp || x.rejectionTimestamp)}</div>*/}
            </NavLink>
          );
        })
      : [];
    return (
      <div className="grid submittal-grid">
        <Loader loading={state.saving} />
        <MessageBox message={state.message} clearMessage={_clearMessage} />
        {renderGridHeader()}
        {state.showFilters && renderFilterRow()}
        {state.loading && (
          <div style={{ width: '100%' }}>
            <StandardGridLoader
              rowCount={14}
              rowPadding={14}
              rowContentHeight={18}
            />
          </div>
        )}
        <div
          className="grid-body custom-scrollbar"
          style={{ maxHeight: '60vh' }}
        >
          {rows}
        </div>
      </div>
    );
  };

  const renderGridHeader = () => {
    const { sortOrder, sortProp, userNotifications } = state;
    const sortIconClass =
      sortOrder === 'DESC'
        ? 'sort-icon fas fa-arrow-down'
        : 'sort-icon fas fa-arrow-up';
    const sortIcon = sortProp ? <span className={sortIconClass}></span> : [];

    return (
      <div className="col-sm-12 row submittal-grid-header">
        <div className="my-col-2 sortable">
          <label onClick={_sort} data-for="submittalNumber">
            Submittal # {sortProp === 'submittalNumber' && sortIcon}
          </label>
        </div>
        <div className="my-col-3">
          <label>Title</label>
        </div>
        <div className="my-col-2 mobile-hide sortable">
          <label onClick={_sort} data-for="division.name">
            Division {sortProp === 'division.name' && sortIcon}
          </label>
        </div>
        <div className="my-col-3 mobile-hide sortable">
          <label onClick={_sort} data-for="subDivision.name">
            Subdivision {sortProp === 'subDivision.name' && sortIcon}
          </label>
        </div>
        <div className="my-col-1 mobile-hide sortable">
          <label onClick={_sort} data-for="date">
            Created {sortProp === 'date' && sortIcon}
          </label>
        </div>
        <div className="my-col-1 mobile-hide sortable">
          <label onClick={_sort} data-for="dueDate">
            Due Date {sortProp === 'dueDate' && sortIcon}
          </label>
        </div>
        <div className="my-col-2 mobile-hide sortable">
          <label onClick={_sort} data-for="status">
            Status {sortProp === 'status' && sortIcon}
          </label>
        </div>
        <div className="my-col-2 mobile-hide sortable">
          <label onClick={_sort} data-for="submitterName">
            Submitted By {sortProp === 'submitterName' && sortIcon}
          </label>
        </div>
        <div className="my-col-2 mobile-hide sortable">
          <label onClick={_sort} data-for="reviewerName">
            Reviewer {sortProp === 'reviewerName' && sortIcon}
          </label>
        </div>
        {/*<div className="my-col-1 mobile-hide sortable">*/}
        {/*    <label onClick={_sort} data-for="approvalTimestamp">*/}
        {/*        Review Date {sortProp === "approvalTimestamp" && sortIcon}*/}
        {/*    </label>*/}
        {/*</div>*/}
        <div
          className="my-col-2"
          style={{
            display: 'flex',
            position: 'absolute',
            right: '0px',
            top: '5px',
          }}
        >
          {props.forPackage ? (
            []
          ) : (
            <span
              className="btn btn-sm btn-blue fas fa-plus"
              onClick={_toggleAddModal}
            ></span>
          )}
          <span
            className="btn btn-sm btn-outline-secondary fas fa-filter"
            onClick={_toggleFilters}
          ></span>
          <button
            className="btn btn-sm btn-outline-secondary fas fa-download"
            onClick={_toggleReportModal}
          ></button>
          <span
            className={cx(
              'btn btn-sm btn-outline-secondary far',
              userNotifications.watchSubmittals ? 'fa-eye-slash' : 'fa-eye'
            )}
            title={
              userNotifications.watchSubmittals
                ? 'Stop automatically being CCed on submittals for this project'
                : 'Automatically be CCed on all submittals on this project'
            }
            onClick={_toggleWatchNotifications}
            style={{ fontSize: '11px' }}
          ></span>
        </div>
      </div>
    );
  };

  const renderFilterRow = () => {
    return (
      <div className="col-sm-12 submittal-grid-filter">
        <div className="my-col-2">
          <input
            name="submittalNumber"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="my-col-3">
          <input name="title" className="form-control" onChange={_filter} />
        </div>
        <div className="my-col-2 mobile-hide">
          <input
            name="division.name"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="my-col-3 mobile-hide">
          <input
            name="subDivision.name"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="my-col-1 mobile-hide">
          <input
            name="date"
            className="form-control"
            type="date"
            onChange={_filter}
          />
        </div>
        <div className="my-col-1 mobile-hide">
          <input
            name="dueDate"
            className="form-control"
            type="date"
            onChange={_filter}
          />
        </div>
        <div className="my-col-2 mobile-hide">
          <select
            name="status"
            className="form-control"
            onChange={_filterOnSelect}
          >
            <option value="">Select...</option>
            <option value="0">Draft</option>
            <option value="1">Submitted</option>
            <option value="2">Reviewed</option>
            <option value="5">Creator Review</option>
            <option value="7">Void</option>
            <option value="3">Rejected</option>
          </select>
        </div>
        <div className="my-col-2 mobile-hide">
          <input
            name="submitterName"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="my-col-2 mobile-hide">
          <input
            name="reviewerName"
            className="form-control"
            onChange={_filter}
          />
        </div>
        <div className="my-col-2 mobile-hide">
          {/*<input name='approvedTimestamp' className='form-control' type='date' onChange={_filter} />*/}
        </div>
      </div>
    );
  };

  console.log('rendering......................................');
  const { showAddModal, showReportModal, sortOrder, sortProp } = state;
  let { submittals } = state;
  if (sortProp) {
    submittals = submittals.sort((a, b) => {
      let aVal = a,
        bVal = b;
      for (const part of sortProp.split('.')) {
        (aVal = aVal[part]), (bVal = bVal[part]);
        if (aVal === null || bVal === null) break;
        //(aVal = aVal ? aVal[x] : undefined), (bVal = bVal ? bVal[x] : undefined);
      }
      if (bVal === undefined || bVal === null) return -1;
      if (sortOrder === 'DESC') return aVal > bVal ? -1 : 1;
      else return aVal > bVal ? 1 : -1;
    });
  }

  return (
    <div>
      {renderGrid(submittals)}
      {showAddModal && (
        <AddSubmittalModal
          projectId={state.projectId}
          close={_toggleAddModal}
          addCallback={_add}
        />
      )}
      {showReportModal && (
        <ReportModal projectId={state.projectId} close={_toggleReportModal} />
      )}
    </div>
  );
};

const ReportModal = (props: { projectId: number; close: () => void }) => {
  const [form, setForm] = React.useState({
    startDate: '',
    endDate: '',
    valid: false,
  });

  React.useEffect(() => {
    if (
      form.startDate &&
      form.endDate &&
      new Date(form.startDate) <= new Date(form.endDate)
    ) {
      if (!form.valid) setForm({ ...form, valid: true });
    } else {
      if (form.valid) setForm({ ...form, valid: false });
    }
  }, [form.startDate, form.endDate]);

  return (
    <Modal>
      <div className="modal-header">
        <h4>Submittal Reports</h4>
      </div>
      <div className="modal-body">
        <p>
          <small>Select a date range to pull submittals for</small>
        </p>
        <div style={{ display: 'flex' }}>
          <div
            className="form-group"
            style={{ width: '50%', paddingRight: '20px' }}
          >
            <label>Start Date*</label>
            <input
              type="date"
              className="form-control"
              value={form.startDate}
              onChange={(e) =>
                setForm({ ...form, startDate: e.currentTarget.value })
              }
            />
          </div>
          <div className="form-group" style={{ width: '50%' }}>
            <label>End Date*</label>
            <input
              type="date"
              className="form-control"
              value={form.endDate}
              onChange={(e) =>
                setForm({ ...form, endDate: e.currentTarget.value })
              }
            />
          </div>
        </div>
        {form.valid && (
          <FileDownloader
            actionPath="api/report/submittalrangepdf"
            queryParams={{
              id: props.projectId,
              start: form.startDate,
              end: form.endDate,
            }}
            fileType="PDF"
          />
        )}
        <hr style={{ borderColor: 'gray' }} />
        <b>Logs (Excel)</b> &nbsp;
        <br />
        {/*<p><small>Saves to the project's 'Logs' folder</small></p>*/}
        <FileDownloader
          actionPath="api/submittal/log"
          queryParams={{ id: props.projectId }}
          fileType="Excel Log"
        />
      </div>
      <div className="modal-footer">
        <button
          style={{ marginLeft: '1%' }}
          className="btn btn-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
