import * as React from "react";
import * as cx from "classnames";
import { formatDate } from "../helpers/formatters";
import {
	ChecklistItemVm,
	ChecklistStatus,
	ChecklistType,
} from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { ChecklistDetailModal } from "./ChecklistDetailModal";
import { Link } from "react-router-dom";

interface ChecklistGridApi {
	toggleDetailId: (id: number | null) => void;
	refreshList: () => void;
}

export interface ChecklistGridProps {
	toggleAddModal: () => void;
	parentRecordId: number | null;
	type: ChecklistType;
	renderTimestamp: number;
}

export const ChecklistsGridContext = React.createContext(
	{} as ChecklistGridApi
);

export const ChecklistGrid = (props: ChecklistGridProps) => {
	const { renderTimestamp, parentRecordId } = props,
		forUser = parentRecordId === null;
	const [checklists, setChecklists] = React.useState([] as ChecklistItemVm[]);
	const [detailId, setDetailId] = React.useState(null as number | null);
	const [loading, setLoading] = React.useState(true);

	const _getChecklists = () => {
		const url = getUrl(props);
		fetch(url)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setChecklists(data);
					setLoading(false);
				}
			});
	};

	React.useEffect(() => {
		_getChecklists();
	}, [renderTimestamp, props.parentRecordId]);

	const api = {
		toggleDetailId: (id) => setDetailId(id),
		refreshList: () => _getChecklists(),
	} as ChecklistGridApi;

	const gridLines = loading ? (
		<StandardGridLoader />
	) : (
		checklists.map((x) => {
			const colorClass = getStatusColorClass(x.status);
			return (
				<div
					className={cx("sv-grid-line", colorClass)}
					key={x.id + "-" + renderTimestamp}
					onClick={() => setDetailId(x.id)}
				>
					<div className="my-col-4 truncate mobile-full-width">
						{x.headerTitle}
					</div>
					{forUser && (
						<div className="my-col-2 mobile-hide">{typeToText(x.type)}</div>
					)}
					{forUser && (
						<div className="my-col-3 truncate mobile-hide">
							<Link
								to={`/project/${x.parentRecordId}#checklists`}
								onClick={(e) => e.stopPropagation()}
							>
								{x.parentRecordName}
							</Link>
						</div>
					)}
					<div className="my-col-3 mobile-hide">
						{formatDate(x.createdDate)}
					</div>
					<div className="my-col-3 mobile-hide">
						{x.dueDate ? formatDate(x.dueDate) : ""}
					</div>
					<div className="my-col-3 mobile-hide">
						{x.answeredFields} / {x.totalFields}
					</div>
					<div className="my-col-2 mobile-hide">{statusToText(x.status)}</div>
				</div>
			);
		})
	);

	const detailChecklist = detailId
			? checklists.find((x) => x.id === detailId)
			: null,
		detailType = detailChecklist ? detailChecklist.type : null;

	return (
		<div key={renderTimestamp}>
			<ChecklistsGridContext.Provider value={api}>
				<div className="grid sv-grid">
					<div className="sv-grid-header" style={{ marginLeft: "10px" }}>
						<div className="my-col-4 mobile-full-width">Title</div>
						{forUser && <div className="my-col-2 mobile-hide">Type</div>}
						{forUser && (
							<div className="my-col-3 mobile-hide">Parent Record</div>
						)}
						<div className="my-col-3 mobile-hide">Created Date</div>
						<div className="my-col-3 mobile-hide">Due Date</div>
						<div className="my-col-3 mobile-hide">Answered / Total</div>
						<div className="my-col-2 mobile-hide">Status</div>
						<div className="sv-grid-header-buttons">
							{!forUser ? (
								<div
									className="btn btn-sm btn-blue fas fa-plus"
									onClick={props.toggleAddModal}
								></div>
							) : (
								[]
							)}
						</div>
					</div>
					<div className="grid-body sv-grid-body custom-scrollbar">
						{gridLines}
					</div>
				</div>
				{detailId && detailType !== null && (
					<ChecklistDetailModal id={detailId} type={detailType} />
				)}
			</ChecklistsGridContext.Provider>
		</div>
	);
};

const getUrl = (info: ChecklistGridProps) => {
	if (info.parentRecordId === null) return `api/UserChecklist/ForUser`;
	switch (info.type) {
		case ChecklistType.Project:
			return `api/ProjectChecklist/GetAll?recordId=${info.parentRecordId}`;
		case ChecklistType.Vendor:
			return `api/VendorChecklist/GetAll?recordId=${info.parentRecordId}`;
		default:
			return `api/UserChecklist/ForUser`;
	}
};

export const getTypeController = (type: ChecklistType) => {
	switch (type) {
		case ChecklistType.Project:
			return "ProjectChecklist";
		case ChecklistType.Vendor:
			return "VendorChecklist";
	}
};

const statusToText = (s: ChecklistStatus) => {
	switch (s) {
		case ChecklistStatus.Started:
			return "Started";
		case ChecklistStatus.Completed:
			return "Completed";
		case ChecklistStatus.Approved:
			return "Approved";
		case ChecklistStatus.Canceled:
			return "Canceled";
	}
};

export const typeToText = (t: ChecklistType) => {
	switch (t) {
		case ChecklistType.Project:
			return "Project";
		case ChecklistType.Vendor:
			return "Vendor";
	}
};

const getStatusColorClass = (s: ChecklistStatus) => {
	switch (s) {
		case ChecklistStatus.Started:
			return "left-tab-yellow";
		case ChecklistStatus.Completed:
			return "left-tab-blue";
		case ChecklistStatus.Approved:
			return "left-tab-green";
		case ChecklistStatus.Canceled:
			return "left-tab-red";
	}
};
