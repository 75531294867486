//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction } from './index';
import { CustomFieldEntry, RFI, RFIStatus } from '../interfaces/interfaces';

export interface RFIPostModel {
  projectId?: number,
  subject: string,
  description: string,
  drawingRef: string,
  suggestedAnswer: string,
  dueDate: string,
  costImpact?: string,
  scheduleImpact?: string,
  reviewerList: RFIReviewerListItem[],
  ccIds: string[],
  ccEmails: string[],
  status: RFIStatus
}

export interface RFIReviewerListItem {
  id: string,
  discipline: string
}

export interface Store {
  projectId: number
  projectNumber: string
  rfis: RFI[]
  unfilteredRFIs: RFI[],
  customFields: CustomFieldEntry[],
  showRFIForm: boolean,
  message?: string,
  loading: boolean,
  saving: boolean,
}

interface RequestListsAction {
  type: 'REQUEST_LISTS',
  projectId: number
}

interface ReceiveListsAction {
  type: 'RECEIVE_LISTS',
  projectId: number,
  projectNumber: string,
  rfis: RFI[],
  unfilteredRFIs: RFI[]
}

interface GetCustomFieldsAction {
  type: 'GET_RFI_CUSTOM_FIELDS',
  customFields: CustomFieldEntry[]
}

interface AddingAction {
  type: 'ADDING_RFI'
}

interface AddedAction {
  type: 'ADDED_RFI',
  rfis?: RFI[],
  unfilteredRFIs?: RFI[]
  submittals?: RFI[]
}

interface FilterAction {
  type: 'FILTER_RFIS',
  rfis: RFI[],
}

interface RFIErrorAction {
  type: 'RFI_ERROR',
  message: string
}

interface ClearMessageAction {
  type: 'CLEAR_RFI_MESSAGE';
}

interface ToggleSavingAction {
  type: 'TOGGLE_SAVING';
}

interface ToggleRFIFormAction {
  type: 'TOGGLE_RFI_FORM';
}

type KnownAction = RequestListsAction | ReceiveListsAction | GetCustomFieldsAction | AddingAction
  | AddedAction | FilterAction | ToggleRFIFormAction | RFIErrorAction | ToggleSavingAction | ClearMessageAction

export const actionCreators = {
  getCustomFields: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const id = getState().rfi.projectId
    fetch(`api/RFI/CustomFields?projectId=${id}`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) dispatch({ type: 'GET_RFI_CUSTOM_FIELDS', customFields: data })
        else dispatch({ type: 'RFI_ERROR', message: data.message })
      })
  },
  request: (projectId: number): AppThunkAction<KnownAction> => (dispatch) => {
    fetch(`api/RFI/Get?projectId=${projectId}`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch({ type: 'RECEIVE_LISTS', rfis: data.rfis, unfilteredRFIs: data.rfis, projectId: projectId, projectNumber: data.projectNumber })
        }
        else {
          dispatch({ type: 'RFI_ERROR', message: data.message })
        }
      })
    dispatch({ type: 'REQUEST_LISTS', projectId: projectId })
  },
  toggleRFIForm: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'TOGGLE_RFI_FORM' })
  },
  addRFI: (model: RFIPostModel, files: FileList | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const form = new FormData()
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; ++i) //form.append(`doc${i}`, files[i], files[i].name)
        form.append('files', files[i], files[i].name)
    }
    model.projectId = getState().rfi.projectId
    form.append("json", JSON.stringify(model))
    fetch(`api/RFI/RFI`, {
      //let saveTask = fetch(`api/RFI/RFI?projectId=${getState().rfi.projectId}&subject=${subject}&description=${description}&answer=${answer}&dueDate=${dueDate}&reviewerId=${reviewerId}`, {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: form
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const rfis = getState().rfi.rfis.slice()
          const unfiltered = getState().rfi.unfilteredRFIs.slice()
          rfis.unshift(data.rfi)
          unfiltered.unshift(data.rfi)
          dispatch({ type: 'ADDED_RFI', rfis: rfis, unfilteredRFIs: unfiltered })
        }
        else {
          dispatch({ type: 'RFI_ERROR', message: data.message })
        }
      })
    dispatch({ type: 'ADDING_RFI' })
  },
  filter: (prop: string, val: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const normalizedVal = val.toUpperCase()
    let filtered = getState().rfi.unfilteredRFIs.slice()
    if (prop === 'reviewers') {
      filtered = filtered.filter(x => {
        if (x.reviewers) {
          for (let i = 0; i < x.reviewers.length; ++i) {
            if (x.reviewers[i].userFullName.toUpperCase().indexOf(normalizedVal) !== -1) return true;
          }
        }
        return false;
      })
    }
    else {
      filtered = filtered.filterByStringProp(prop, val)
    }

    dispatch({ type: 'FILTER_RFIS', rfis: filtered })
  },
  updateCustomField: (rfiId: number, fieldId: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(`api/rfi/customfield?projectId=${getState().rfi.projectId}&fieldId=${fieldId}&value=${encodeURIComponent(value)}`, { method: 'PUT' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch({ type: 'GET_RFI_CUSTOM_FIELDS', customFields: data })
        }
        else {
          dispatch({ type: 'RFI_ERROR', message: data.message })
        }
      })
  },
  toggleSaving: (): AppThunkAction<KnownAction> => (dispatch) => dispatch({ type: 'TOGGLE_SAVING' }),
  clearMessage: () => (dispatch) => dispatch({ type: 'CLEAR_RFI_MESSAGE' })
}

const unloadedState: Store = {
  projectId: 0,
  projectNumber: '',
  rfis: [],
  unfilteredRFIs: [],
  customFields: [],
  showRFIForm: false,
  loading: true,
  saving: false,
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_LISTS':
      return { ...state, projectId: action.projectId, loading: true }
    case 'RECEIVE_LISTS': return {
      ...state,
      projectId: action.projectId,
      rfis: action.rfis,
      unfilteredRFIs: action.unfilteredRFIs,
      projectNumber: action.projectNumber,
      loading: false,
    }
    case 'GET_RFI_CUSTOM_FIELDS':
      return { ...state, customFields: action.customFields, loading: false }
    case 'ADDING_RFI': return { ...state, saving: true }
    case 'ADDED_RFI':
      return {
        ...state,
        rfis: action.rfis || state.rfis,
        unfilteredRFIs: action.unfilteredRFIs || state.unfilteredRFIs,
        message: 'Saved',
        showRFIForm: false,
        saving: false
      }
    case 'FILTER_RFIS':
      return { ...state, rfis: action.rfis }
    case "TOGGLE_RFI_FORM":
      return { ...state, showRFIForm: !state.showRFIForm }
    case "TOGGLE_SAVING": return { ...state, saving: !state.saving }
    case 'RFI_ERROR': return { ...state, message: action.message, saving: false, loading: false }
    case 'CLEAR_RFI_MESSAGE': return { ...state, message: undefined }
    default: {
      const exhaustiveCheck: never = action;
    }
  }
  return state || unloadedState
}